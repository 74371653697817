.ps {
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  overflow: hidden !important;
}

.ps__rail-x {
  opacity: 0;
  height: 15px;
  transition: background-color .2s linear, opacity .2s linear;
  display: none;
  position: absolute;
  bottom: 0;
}

.ps__rail-y {
  opacity: 0;
  width: 15px;
  transition: background-color .2s linear, opacity .2s linear;
  display: none;
  position: absolute;
  right: 0;
}

.ps--active-x > .ps__rail-x, .ps--active-y > .ps__rail-y {
  background-color: rgba(0, 0, 0, 0);
  display: block;
}

.ps:hover > .ps__rail-x, .ps:hover > .ps__rail-y, .ps--focus > .ps__rail-x, .ps--focus > .ps__rail-y, .ps--scrolling-x > .ps__rail-x, .ps--scrolling-y > .ps__rail-y {
  opacity: .6;
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
  opacity: .9;
  background-color: #eee;
}

.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  height: 6px;
  transition: background-color .2s linear, height .2s ease-in-out;
  position: absolute;
  bottom: 2px;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  width: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  position: absolute;
  right: 2px;
}

.ps__rail-x:hover > .ps__thumb-x, .ps__rail-x:focus > .ps__thumb-x, .ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

.scrollbar-container {
  height: 100%;
  position: relative;
}

.contentTeaserGrid-module__h3jyCW__header {
  margin-bottom: var(--zbx-spacing-2);
}

.contentTeaserGrid-module__h3jyCW__title h2 {
  padding: var(--zbx-spacing-4) var(--zbx-spacing-4);
  text-align: center;
  margin: 0;
}

.contentTeaserGrid-module__h3jyCW__noSubtitle {
  margin-bottom: var(--zbx-spacing-9);
}

.contentTeaserGrid-module__h3jyCW__subtitle {
  padding: var(--zbx-spacing-4) var(--zbx-spacing-4) var(--zbx-spacing-1) var(--zbx-spacing-4);
  margin: 0 auto;
}

.contentTeaserGrid-module__h3jyCW__subtitle, .contentTeaserGrid-module__h3jyCW__subtitle p {
  text-align: center;
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-xl);
  line-height: 1.18;
  font-weight: var(--font-weight-light);
  max-width: 100%;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .contentTeaserGrid-module__h3jyCW__subtitle, .contentTeaserGrid-module__h3jyCW__subtitle p {
    max-width: 80%;
    padding-inline: var(--zbx-spacing-8);
  }
}

.contentTeaserGrid-module__h3jyCW__contentGridCard {
  padding: var(--zbx-spacing-3) var(--zbx-spacing-5);
  margin-bottom: var(--zbx-spacing-2);
}

[class$="cardText"] p:first-of-type {
  margin-bottom: var(--zbx-spacing-4);
}

@media (max-width: 550px) {
  .contentTeaserGrid-module__h3jyCW__contentGridCard {
    padding: var(--zbx-spacing-3) var(--zbx-spacing-2);
  }
}

.contentTeaserGrid-module__h3jyCW__contentGridCard > div {
  gap: 0;
}

.contentTeaserGrid-module__h3jyCW__contentGridCard > div > div {
  border-radius: 0;
}

@media (min-width: 600px) {
  .contentTeaserGrid-module__h3jyCW__subtitle p {
    font-size: var(--font-size-2xl);
    line-height: var(--line-height-sm);
  }

  .contentTeaserGrid-module__h3jyCW__contentGridCard {
    padding: 0 var(--zbx-spacing-7);
    margin-bottom: var(--zbx-spacing-8);
  }
}

@media (min-width: 960px) {
  .contentTeaserGrid-module__h3jyCW__contentGridCard {
    padding: var(--zbx-spacing-3) var(--zbx-spacing-7);
  }
}

.faq-module__NO6lxa__faq {
  width: auto;
  font-family: var(--zbx-font-lato);
  padding-inline: var(--zbx-spacing-5);
}

.faq-module__NO6lxa__faq--withBg {
  background-color: var(--faq-bg);
}

.faq-module__NO6lxa__faq__header {
  text-align: center;
  margin: 0 auto;
  padding: .75rem;
}

.faq-module__NO6lxa__faq__title {
  width: 100%;
  font-size: var(--font-size-35px);
  color: var(--primary-2);
  -webkit-text-fill-color: var(--primary-2);
  margin: 0 auto 1.8125rem;
}

.faq-module__NO6lxa__faq__subtitle {
  padding: var(--zbx-spacing-4) 0 0;
  margin: 0 auto 1rem;
}

.faq-module__NO6lxa__faq__subtitle p, .faq-module__NO6lxa__faq__subtitle span {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-light);
  letter-spacing: normal;
  line-height: var(--line-height-sm);
  margin: 0;
}

.faq-module__NO6lxa__faq__accordion {
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
}

.faq-module__NO6lxa__faq__root {
  width: 100%;
  padding: 1.5rem .75rem;
}

.faq-module__NO6lxa__faq__item {
  border-bottom: var(--faq-border);
}

.faq-module__NO6lxa__faq__item:first-child {
  border-top: var(--faq-border);
}

.faq-module__NO6lxa__faq__trigger {
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
  justify-content: space-between;
  align-items: center;
  gap: 1.75rem;
  width: 100%;
  padding: 1rem 0;
  display: flex;
}

.faq-module__NO6lxa__faq__icons {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.faq-module__NO6lxa__faq__iconPlus, .faq-module__NO6lxa__faq__iconMinus {
  width: 24px;
  height: 24px;
  fill: var(--secondary-1);
  margin-right: var(--margin-sm);
  background: none;
  border-radius: 50%;
}

.faq-module__NO6lxa__faq__iconMinus, .faq-module__NO6lxa__faq__trigger[data-state="open"] .faq-module__NO6lxa__faq__iconPlus {
  display: none;
}

.faq-module__NO6lxa__faq__trigger[data-state="open"] .faq-module__NO6lxa__faq__iconMinus {
  background: var(--primary-1);
  width: 24px;
  height: 24px;
  fill: var(--primary-3);
  border-radius: 50%;
  margin-right: 0;
  padding: .5rem;
  display: block;
}

.faq-module__NO6lxa__faq__text {
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-xl);
  color: var(--primary-2);
  -webkit-text-fill-color: var(--primary-2);
  letter-spacing: normal;
  background-repeat: no-repeat;
  flex: auto;
  font-weight: 300;
}

.faq-module__NO6lxa__faq__trigger[data-state="open"] .faq-module__NO6lxa__faq__text {
  font-weight: 400;
}

.faq-module__NO6lxa__faq__content {
  overflow: hidden;
}

.faq-module__NO6lxa__faq__content[data-state="open"] {
  animation: .3s cubic-bezier(.87, 0, .13, 1) forwards faq-module__NO6lxa__slideDown;
}

.faq-module__NO6lxa__faq__content[data-state="closed"] {
  animation: .3s cubic-bezier(.87, 0, .13, 1) forwards faq-module__NO6lxa__slideUp;
}

.faq-module__NO6lxa__faq__content > div {
  font-size: var(--font-size-lg);
  padding: 0 0 2rem;
  font-weight: 300;
  line-height: 1.22;
}

.faq-module__NO6lxa__faq__content > div a {
  color: var(--gold);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  text-decoration: none;
}

.faq-module__NO6lxa__faq__content > div a:hover {
  text-decoration: underline;
}

.faq-module__NO6lxa__faq__content table {
  border-collapse: collapse;
}

.faq-module__NO6lxa__faq__content tr {
  border-bottom: var(--faq-border);
}

.faq-module__NO6lxa__faq__buttonWrapper {
  justify-content: center;
  margin-bottom: 3rem;
  padding: .75rem;
  display: flex;
}

.faq-module__NO6lxa__faq__buttonWrapper a {
  padding: 0 var(--zbx-spacing-8);
}

@keyframes faq-module__NO6lxa__slideDown {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes faq-module__NO6lxa__slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}

@media (min-width: 768px) {
  .faq-module__NO6lxa__faq__title {
    font-size: 48px;
  }
}

@media (min-width: 960px) {
  .faq-module__NO6lxa__faq {
    padding-inline: var(--zbx-spacing-8);
  }

  .faq-module__NO6lxa__faq__root {
    width: var(--breakpoint-lg);
    margin: 0 auto;
  }

  .faq-module__NO6lxa__faq__subtitle {
    font-size: var(--font-size-2xl);
  }

  .faq-module__NO6lxa__faq__accordion {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1440px) {
  .faq-module__NO6lxa__faq__item {
    width: 100%;
  }
}

.Gallery-module__EtmKAW__sliderSlide {
  aspect-ratio: 1;
  display: block;
  position: relative;
}

@media only screen and (min-width: 960px) {
  .Gallery-module__EtmKAW__sliderSlide {
    aspect-ratio: 16 / 9;
  }
}

.Gallery-module__EtmKAW__responsiveImage {
  object-fit: cover;
  object-position: center;
}

.Gallery-module__EtmKAW__sliderArrow {
  z-index: var(--z-index-gallery-arrows);
  cursor: pointer;
  background-color: #fff;
  width: 62px;
  height: 62px;
  position: absolute;
  transform: scale(1.275)translateY(-50%);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px rgba(0, 0, 0, .14), 0 1px 18px rgba(0, 0, 0, .12);
}

.Gallery-module__EtmKAW__sliderArrow:hover {
  background-color: var(--goldBtn);
}

.Gallery-module__EtmKAW__previewBox {
  cursor: default;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 80%;
  height: 100%;
  max-height: 80%;
  display: flex;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(1)rotate(0);
}

.Gallery-module__EtmKAW__previewBox img {
  max-width: 100%;
  max-height: 100%;
}

.Gallery-module__EtmKAW__closeButton {
  top: 10%;
  right: 2%;
}

.Gallery-module__EtmKAW__blurredBackground {
  width: 100%;
  height: 100%;
  z-index: var(--z-index-modal-bg);
  background: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  left: 0;
}

.Gallery-module__EtmKAW__rotateButtons {
  z-index: var(--z-index-modal);
  gap: 0;
  display: flex;
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.Gallery-module__EtmKAW__rotateButton {
  cursor: pointer;
  color: var(--primary-3);
  background-color: #2d2d2d;
}

.Gallery-module__EtmKAW__sliderArrowLeft {
  top: 50%;
  left: 10%;
}

.Gallery-module__EtmKAW__sliderArrowRight {
  top: 50%;
  right: 10%;
}

.Gallery-module__EtmKAW__previewArrowLeft {
  left: 100px;
}

.Gallery-module__EtmKAW__previewArrowRight {
  right: 100px;
}

@media only screen and (max-width: 959px) {
  .Gallery-module__EtmKAW__sliderArrowLeft {
    top: 50%;
    left: 10%;
  }

  .Gallery-module__EtmKAW__sliderArrowRight {
    top: 50%;
    right: 10%;
  }

  .Gallery-module__EtmKAW__sliderArrow {
    transform: scale(.75)translateY(-50%);
  }

  .Gallery-module__EtmKAW__previewArrowLeft {
    left: 5px;
  }

  .Gallery-module__EtmKAW__previewArrowRight {
    right: 5px;
  }

  .Gallery-module__EtmKAW__closeButton {
    top: 25%;
  }

  .Gallery-module__EtmKAW__sliderArrowLeft {
    left: 20px;
  }

  .Gallery-module__EtmKAW__sliderArrowRight {
    right: 20px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1280px) {
  .Gallery-module__EtmKAW__sliderArrow {
    transform: scale(1.275)translateY(-50%);
  }
}

@media only screen and (max-width: 600px) {
  .Gallery-module__EtmKAW__sliderArrowLeft {
    top: calc(50% - 20px);
    left: 5px;
  }

  .Gallery-module__EtmKAW__sliderArrowRight {
    top: calc(50% - 20px);
    right: 5px;
  }
}

.yarl__fullsize {
  width: 100%;
  height: 100%;
}

.yarl__relative {
  position: relative;
}

.yarl__portal {
  opacity: 0;
  transition: opacity var(--yarl__fade_animation_duration, .25s) var(--yarl__fade_animation_timing_function, ease);
  z-index: var(--yarl__portal_zindex, 9999);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.yarl__portal_open {
  opacity: 1;
}

.yarl__container {
  background-color: var(--yarl__container_background_color, var(--yarl__color_backdrop, #000));
  overscroll-behavior: var(--yarl__controller_overscroll_behavior, contain);
  touch-action: var(--yarl__controller_touch_action, none);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  outline: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.yarl__carousel {
  height: 100%;
  opacity: var(--yarl__pull_opacity, 1);
  transform: translate(var(--yarl__swipe_offset, 0), var(--yarl__pull_offset, 0));
  width: calc(100% + (var(--yarl__carousel_slides_count)  - 1) * (100% + var(--yarl__carousel_spacing_px, 0) * 1px + var(--yarl__carousel_spacing_percent, 0) * 1%));
  flex: none;
  place-content: center;
  align-items: stretch;
  display: flex;
}

.yarl__carousel_with_slides {
  -moz-column-gap: calc(var(--yarl__carousel_spacing_px, 0) * 1px + 100 / (var(--yarl__carousel_slides_count) * 100 + (var(--yarl__carousel_slides_count)  - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_spacing_percent, 0) * 1%);
  column-gap: calc(var(--yarl__carousel_spacing_px, 0) * 1px + 100 / (var(--yarl__carousel_slides_count) * 100 + (var(--yarl__carousel_slides_count)  - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_spacing_percent, 0) * 1%);
}

.yarl__flex_center {
  place-content: center;
  align-items: center;
  display: flex;
}

.yarl__slide {
  padding: calc(var(--yarl__carousel_padding_px, 0) * 1px + 100 / (var(--yarl__carousel_slides_count) * 100 + (var(--yarl__carousel_slides_count)  - 1) * var(--yarl__carousel_spacing_percent, 0)) * var(--yarl__carousel_padding_percent, 0) * 1%);
  flex: 1;
  position: relative;
  overflow: hidden;
}

[dir="rtl"] .yarl__slide {
  --yarl__direction: -1;
}

.yarl__slide_image {
  -o-object-fit: contain;
  object-fit: contain;
  touch-action: var(--yarl__controller_touch_action, none);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  max-width: 100%;
  max-height: 100%;
}

.yarl__slide_image_cover {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.yarl__slide_image_loading {
  opacity: 0;
}

@media screen and (min-width: 800px) {
  .yarl__slide_wrapper:not(.yarl__slide_wrapper_interactive) .yarl__slide_image {
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: translateZ(0);
  }
}

.yarl__slide_placeholder {
  line-height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.yarl__slide_loading {
  color: var(--yarl__slide_icon_loading_color, var(--yarl__color_button, rgba(255, 255, 255, .8)));
  animation: 1s linear yarl__delayed_fadein;
}

.yarl__slide_loading line {
  animation: 1s linear infinite yarl__stroke_opacity;
}

.yarl__slide_loading line:first-of-type {
  animation-delay: -1.875s;
}

.yarl__slide_loading line:nth-of-type(2) {
  animation-delay: -1.75s;
}

.yarl__slide_loading line:nth-of-type(3) {
  animation-delay: -1.625s;
}

.yarl__slide_loading line:nth-of-type(4) {
  animation-delay: -1.5s;
}

.yarl__slide_loading line:nth-of-type(5) {
  animation-delay: -1.375s;
}

.yarl__slide_loading line:nth-of-type(6) {
  animation-delay: -1.25s;
}

.yarl__slide_loading line:nth-of-type(7) {
  animation-delay: -1.125s;
}

.yarl__slide_loading line:nth-of-type(8) {
  animation-delay: -1s;
}

.yarl__slide_error {
  color: var(--yarl__slide_icon_error_color, red);
  height: var(--yarl__slide_icon_error_size, 48px);
  width: var(--yarl__slide_icon_error_size, 48px);
}

@media (prefers-reduced-motion) {
  .yarl__portal, .yarl__slide {
    transition: unset;
  }

  .yarl__slide_loading, .yarl__slide_loading line {
    animation: unset;
  }
}

.yarl__toolbar {
  padding: var(--yarl__toolbar_padding, 8px);
  justify-content: flex-end;
  display: flex;
  position: absolute;
  top: 0;
  bottom: auto;
  left: auto;
  right: 0;
}

[dir="rtl"] .yarl__toolbar {
  top: 0;
  bottom: auto;
  left: 0;
  right: auto;
}

.yarl__icon {
  height: var(--yarl__icon_size, 32px);
  width: var(--yarl__icon_size, 32px);
}

.yarl__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--yarl__button_background_color, transparent);
  border: var(--yarl__button_border, 0);
  color: var(--yarl__color_button, rgba(255, 255, 255, .8));
  cursor: pointer;
  filter: var(--yarl__button_filter, drop-shadow(2px 2px 2px rgba(0, 0, 0, .8)));
  margin: var(--yarl__button_margin, 0);
  padding: var(--yarl__button_padding, 8px);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  line-height: 0;
}

.yarl__button:focus {
  color: var(--yarl__color_button_active, #fff);
}

.yarl__button:focus:not(:focus-visible) {
  color: var(--yarl__color_button, rgba(255, 255, 255, .8));
}

.yarl__button:focus-visible {
  color: var(--yarl__color_button_active, #fff);
}

@media (hover: hover) {
  .yarl__button:focus:hover, .yarl__button:hover {
    color: var(--yarl__color_button_active, #fff);
  }

  .yarl__button:focus-visible:hover {
    color: var(--yarl__color_button_active, #fff);
  }
}

.yarl__button:disabled {
  color: var(--yarl__color_button_disabled, rgba(255, 255, 255, .4));
  cursor: default;
}

.yarl__navigation_next, .yarl__navigation_prev {
  padding: var(--yarl__navigation_button_padding, 24px 16px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.yarl__navigation_prev {
  left: 0;
}

[dir="rtl"] .yarl__navigation_prev {
  left: unset;
  right: 0;
  transform: translateY(-50%)rotate(180deg);
}

.yarl__navigation_next {
  right: 0;
}

[dir="rtl"] .yarl__navigation_next {
  left: 0;
  right: unset;
  transform: translateY(-50%)rotate(180deg);
}

.yarl__no_scroll {
  overscroll-behavior: none;
  height: 100%;
  overflow: hidden;
}

@keyframes yarl__delayed_fadein {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes yarl__stroke_opacity {
  0% {
    stroke-opacity: 1;
  }

  to {
    stroke-opacity: .125;
  }
}

.GridPackagingMaterial-module__R2PXIW__gridPackagingMaterialContainer {
  width: 88%;
  margin: 0 auto;
}

.GridPackagingMaterial-module__R2PXIW__parkagingMaterialCard {
  box-sizing: border-box;
  padding: 0;
}

.GridPackagingMaterial-module__R2PXIW__parkagingMaterialCard:hover {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px rgba(0, 0, 0, .14), 0 1px 5px rgba(0, 0, 0, .12) !important;
}

.GridPackagingMaterial-module__R2PXIW__packagingTabsList div div {
  padding: 0;
}

.GridPackagingMaterial-module__R2PXIW__packagingTabsList > div > div:first-child {
  border-bottom: 1px solid var(--secondary-2);
  padding-bottom: var(--zbx-spacing-3);
}

.GridPackagingMaterial-module__R2PXIW__divider {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  margin-top: 1.25rem;
}

.GridPackagingMaterial-module__R2PXIW__grid {
  border-top: 1px solid var(--secondary-2);
  grid-template-columns: repeat(3, 1fr);
  gap: 2.75rem;
  margin-top: .75rem;
  padding: 2rem 0;
  display: grid;
}

.GridPackagingMaterial-module__R2PXIW__itemContainer {
  flex-direction: column;
  align-items: center;
  gap: .75rem;
  display: flex;
  position: relative;
}

.GridPackagingMaterial-module__R2PXIW__size {
  color: var(--color-primary-2);
  font-family: var(--zbx-font-lato);
  font-size: 1rem;
  font-weight: var(--font-weight-light);
  text-align: center;
}

.GridPackagingMaterial-module__R2PXIW__price {
  color: var(--color-primary-2);
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-lg);
  text-align: center;
  margin-bottom: .5rem;
  font-weight: bold;
  line-height: 1.22;
}

@media (max-width: 959px) {
  .GridPackagingMaterial-module__R2PXIW__gridPackagingMaterialContainer {
    width: 92%;
  }

  .GridPackagingMaterial-module__R2PXIW__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .GridPackagingMaterial-module__R2PXIW__gridPackagingMaterialContainer {
    width: 82%;
  }

  .GridPackagingMaterial-module__R2PXIW__packagingTabsList {
    margin-right: 0;
  }

  .GridPackagingMaterial-module__R2PXIW__grid {
    gap: var(--grid-gap-medium);
    grid-template-columns: 1fr;
  }
}

.tabs-module__XwHbVq__tabsSection {
  grid-template-columns: 100%;
  display: grid;
}

.tabs-module__XwHbVq__tabsList {
  height: 48px;
  padding: 0 var(--zbx-spacing-8);
  max-width: 90%;
  margin-bottom: var(--zbx-spacing-8);
  background: none;
  flex-flow: row;
  align-items: center;
  display: flex;
}

.tabs-module__XwHbVq__tabsList button {
  width: -moz-fit-content;
  width: fit-content;
}

.tabs-module__XwHbVq__TabsScrollarea {
  --scrollbar-size: 10px;
  border-radius: 4px;
  max-width: 100%;
  overflow: hidden;
}

.tabs-module__XwHbVq__TabsScrollViewport {
  white-space: nowrap;
  width: 100%;
  overflow-x: auto;
}

.tabs-module__XwHbVq__TabsScrollViewport div > button, .tabs-module__XwHbVq__tabsList > button {
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-md);
  margin: var(--zbx-spacing-3) 14px;
  cursor: pointer;
  letter-spacing: .5px;
  width: -moz-fit-content;
  width: fit-content;
  height: 25px;
  color: var(--secondary-1);
  background: none;
  border: none;
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  transition: border-bottom .1s ease-in-out;
}

.tabs-module__XwHbVq__TabsScrollViewport div > button.tabs-module__XwHbVq__tabsActive, .tabs-module__XwHbVq__tabsList > button.tabs-module__XwHbVq__tabsActive {
  border-bottom: 4px solid var(--primary-1);
  font-weight: var(--font-weight-bold);
  color: var(--primary-2);
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 20px;
  transition: border-bottom .1s ease-in-out;
}

.tabs-module__XwHbVq__tabsCardsCarousel {
  margin-top: 35px;
}

.tabs-module__XwHbVq__cardsContainer {
  padding: 0 var(--zbx-spacing-10);
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 40px 0;
  margin-top: 35px;
  display: grid;
}

.tabs-module__XwHbVq__cardsContainer > div {
  width: 100%;
  min-width: 150px;
  max-width: 380px;
  box-shadow: none;
  border-radius: 0;
}

.tabs-module__XwHbVq__mapContainer {
  margin-top: 35px;
}

@media only screen and (max-width: 1024px) {
  .tabs-module__XwHbVq__cardsContainer {
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .tabs-module__XwHbVq__TabsScrollViewport div > button, .tabs-module__XwHbVq__tabsList > button {
    padding: 0 var(--zbx-spacing-1);
    margin-left: 0;
  }
}

@media only screen and (max-width: 600px) {
  .tabs-module__XwHbVq__tabsList {
    max-width: 95%;
  }
}

@media only screen and (min-width: 768px) {
  .tabs-module__XwHbVq__cardsContainer {
    column-gap: 30px;
    padding: 0 50px;
  }
}

@media only screen and (min-width: 1024px) {
  .tabs-module__XwHbVq__tabsList {
    padding: 0 5rem;
  }
}

.zbslider-module__yJ5N1W__sliderContainer {
  width: 100%;
  height: auto;
  overflow: hidden;
}

.zbslider-module__yJ5N1W__sliderContainer > div {
  padding-bottom: 15px;
}

.zbslider-module__yJ5N1W__sliderContainer .zbslider-module__yJ5N1W__slick-list .zbslider-module__yJ5N1W__slick-track .zbslider-module__yJ5N1W__slick-slide {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 10px;
}

.zbslider-module__yJ5N1W__sliderButton {
  z-index: 1;
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
}

.zbslider-module__yJ5N1W__sliderButtonNext {
  right: 5px;
}

.zbslider-module__yJ5N1W__sliderButtonPrev {
  left: 5px;
}

.zbslider-module__yJ5N1W__slick-dots li button::marker {
  color: var(--primary-2);
  font-size: 10px;
}

.zbslider-module__yJ5N1W__slick-dots li button:before:hover {
  color: var(--secondary-1);
}

.zbslider-module__yJ5N1W__slick-dots li.zbslider-module__yJ5N1W__slick-active button:before {
  color: var(--primary-1);
}

.circleArrowbutton-module__AmFy7q__container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.circleArrowbutton-module__AmFy7q__arrowButton {
  background-color: var(--primary-3);
  opacity: 1;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  transition: all .2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
}

.circleArrowbutton-module__AmFy7q__arrowButton:hover {
  cursor: pointer;
  background-color: var(--primary-1);
  transition: all .2s;
}

.circleArrowbutton-module__AmFy7q__disabledArrowButton {
  background-color: var(--secondary-3);
}

.map-module__RHL5yq__mapContainer {
  width: 90%;
  height: 720px;
  margin: 0 auto 30px;
  position: relative;
}

.map-module__RHL5yq__mainMapContainer {
  width: 100%;
}

.map-module__RHL5yq__homeMap {
  margin-bottom: -200px;
}

.map-module__RHL5yq__homeMapSlider {
  z-index: 1;
  position: relative;
  bottom: 220px;
  left: 0;
}

.map-module__RHL5yq__homeMapHeader {
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
  position: relative;
}

.map-module__RHL5yq__homeMapHeader:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, .8), #fff);
  height: 70px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  rotate: 180deg;
}

.map-module__RHL5yq__homeMapLead {
  text-align: center;
  width: 95%;
}

.map-module__RHL5yq__homeMapHeader h2 {
  color: var(--primary-2);
  font-family: var(--zbx-font-kievit);
  font-weight: var(--font-weight-bold);
  margin: 0;
}

.map-module__RHL5yq__homeMapHeader p {
  color: var(--primary-2);
  font-family: var(--zbx-font-kievit);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-regular);
  text-align: center;
}

.map-module__RHL5yq__mapStyles {
  width: 100%;
  min-height: 720px;
}

.map-module__RHL5yq__advancedMarker {
  cursor: pointer;
}

.map-module__RHL5yq__pin {
  cursor: pointer;
  transition: all .2s ease-in-out;
  position: relative !important;
}

.map-module__RHL5yq__pinHighlight path {
  fill: var(--primary-1);
}

.map-module__RHL5yq__hidePinTooltip {
  display: none;
}

.map-module__RHL5yq__pinTooltip {
  background-color: var(--primary-3);
  white-space: nowrap;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
}

.map-module__RHL5yq__pinTooltip p {
  word-break: keep-all;
  white-space: nowrap;
  width: 100%;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-light);
  font-family: var(--zbx-font-lato);
  flex-direction: row;
  justify-content: center;
  margin: 0;
  display: flex;
}

.map-module__RHL5yq__pinTooltip:after {
  background-color: var(--primary-3);
  white-space: nowrap;
  opacity: 0;
  border-radius: 4px;
  padding: 5px 10px;
  transition: opacity .3s;
  position: relative;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.map-module__RHL5yq__pinTooltip:before {
  content: "";
  border-style: solid;
  border-width: 5px;
  border-color: transparent transparent transparent var(--primary-3);
  position: absolute;
  top: 30%;
  right: 100%;
  rotate: 180deg;
}

.map-module__RHL5yq__pin:hover .map-module__RHL5yq__pinTooltip:after, .map-module__RHL5yq__pin:hover .map-module__RHL5yq__pinTooltip:before {
  opacity: 1;
}

.map-module__RHL5yq__pinTip {
  border: 8px solid var(--estate-green-dark);
  border-radius: 0 0 5px;
  width: 0;
  height: 0;
  transition: all .2s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateY(22%)translateX(-50%)rotate(45deg);
}

.map-module__RHL5yq__zoomControl {
  border: 2px solid var(--secondary-2);
  border-radius: 5px;
  position: absolute;
  right: 100px;
}

.map-module__RHL5yq__zoomControl button {
  background-color: var(--primary-3);
  color: var(--primary-2);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  font-family: Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif;
  font-size: var(--font-size-xl);
  border: none;
  padding: 2px 6px;
  transition: all .2s ease-in-out;
}

.map-module__RHL5yq__zoomControl button:hover {
  background-color: var(--secondary-2);
}

.map-module__RHL5yq__zoomControl button:first-child {
  border-bottom: 1px solid var(--secondary-3);
}

.map-module__RHL5yq__infoPopup {
  border-radius: 0;
  width: 100%;
  min-width: -moz-fit-content;
  min-width: fit-content;
  height: auto;
}

.map-module__RHL5yq__infoPopupTitle {
  width: -moz-fit-content;
  width: fit-content;
  color: var(--primary-2);
  font-family: var(--zbx-font-kievit);
  font-weight: var(--font-weight-bold);
  margin: 0;
}

.map-module__RHL5yq__infoPopupContent {
  color: var(--primary-2);
  font-family: var(--zbx-font-Lato);
  font-weight: var(--font-weight-regular);
  margin: 0;
}

.map-module__RHL5yq__infoPopupContent a, .map-module__RHL5yq__infoPopupContent p a {
  color: var(--gold);
  font-weight: var(--font-weight-bold);
  text-decoration: none;
}

.map-module__RHL5yq__infoPopupContent a:hover, .map-module__RHL5yq__infoPopupContent p a:hover {
  text-decoration: underline;
}

.map-module__RHL5yq__infoPopupLink {
  width: 40%;
  height: -moz-fit-content;
  height: fit-content;
}

@media (max-width: 780px) {
  .map-module__RHL5yq__infoPopupLink {
    width: 35%;
    height: -moz-fit-content;
    height: fit-content;
  }
}

.map-module__RHL5yq__infoPopupBookNow {
  height: 42px;
}

@media screen and (max-width: 1440px) {
  .map-module__RHL5yq__homeMapContainer {
    margin: 0;
  }
}

@media screen and (max-width: 426px) {
  .map-module__RHL5yq__zoomControl {
    top: unset;
    bottom: 15rem;
    right: 20px;
  }
}

@media screen and (min-width: 768px) {
  .map-module__RHL5yq__homeMapLead {
    width: 65%;
  }

  .map-module__RHL5yq__mainMapContainer {
    width: 98%;
  }
}

@media screen and (min-width: 1024px) {
  .map-module__RHL5yq__mapContainer {
    width: 100%;
  }

  .map-module__RHL5yq__homeMapLead {
    width: 50%;
  }
}

@media screen and (min-width: 1440px) {
  .map-module__RHL5yq__mapContainer {
    padding: 0;
  }

  .map-module__RHL5yq__homeMapSlider {
    max-width: 85%;
    margin: 0 auto;
  }
}

.faqs-module___s1DZq__faqsContainer {
  max-width: 920px;
  margin: 0 var(--zbx-spacing-8) var(--zbx-spacing-13);
}

.faqs-module___s1DZq__filterContainer {
  justify-content: center;
  align-items: center;
  max-width: 100%;
  display: flex;
  position: relative;
}

.faqs-module___s1DZq__filterScroll {
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: var(--zbx-spacing-2);
  scroll-behavior: smooth;
  display: flex;
  overflow-x: auto;
}

.faqs-module___s1DZq__filterScroll::-webkit-scrollbar {
  display: none;
}

.faqs-module___s1DZq__filterButton {
  background-color: var(--secondary-3);
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-light);
  padding: var(--zbx-spacing-2) var(--zbx-spacing-4);
  margin: 0 var(--zbx-spacing-2);
  white-space: nowrap;
  cursor: pointer;
  border: none;
  border-radius: 9999px;
  transition: background-color .3s;
}

.faqs-module___s1DZq__filterButton:hover, .faqs-module___s1DZq__filterButton:active {
  background-color: #e4e4e4;
}

.faqs-module___s1DZq__activeFilter, .faqs-module___s1DZq__activeFilter:hover, .faqs-module___s1DZq__activeFilter:active {
  background-color: var(--primary-1);
  color: var(--primary-2);
  pointer-events: none;
}

.faqs-module___s1DZq__scrollButton {
  width: var(--zbx-spacing-10);
  height: var(--zbx-spacing-10);
  cursor: pointer;
  margin: 0 var(--zbx-spacing-3);
  padding: var(--zbx-spacing-3);
  background: none;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  transition: background-color .3s;
  display: flex;
}

.faqs-module___s1DZq__scrollButton:hover {
  background-color: var(--secondary-3);
}

.faqs-module___s1DZq__scrollArrow {
  fill: var(--secondary-1);
  color: var(--secondary-1);
}

@media (max-width: 768px) {
  .faqs-module___s1DZq__scrollButton {
    display: none;
  }

  .faqs-module___s1DZq__filterScroll {
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    padding: var(--zbx-spacing-2) 0;
    overflow-x: scroll;
  }
}

@media (min-width: 960px) {
  .faqs-module___s1DZq__faqsContainer {
    margin: 0 auto var(--zbx-spacing-13);
  }
}

.teaserNextBestActions-module__cMOB9G__container {
  max-width: 1440px;
}

.teaserNextBestActions-module__cMOB9G__titleWrapper {
  padding: var(--zbx-spacing-3);
}

.teaserNextBestActions-module__cMOB9G__title {
  text-align: center;
  margin: 0 0 var(--zbx-spacing-9);
}

.teaserNextBestActions-module__cMOB9G__grid {
  grid-template-columns: 1fr;
  gap: 1.75rem;
  display: grid;
}

.teaserNextBestActions-module__cMOB9G__item {
  text-align: center;
  padding: .75rem .75rem 1.75rem;
}

.teaserNextBestActions-module__cMOB9G__imageWrapper {
  aspect-ratio: 4 / 2;
  width: 100%;
  margin-bottom: 1.75rem;
  position: relative;
  overflow: hidden;
}

.teaserNextBestActions-module__cMOB9G__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: transform .2s ease-in-out;
}

.teaserNextBestActions-module__cMOB9G__image:hover {
  transform: scale(1.05);
}

.teaserNextBestActions-module__cMOB9G__overTitle {
  font-size: var(--font-size-md);
  text-align: center;
  margin-top: .75rem;
  margin-bottom: 0;
}

.teaserNextBestActions-module__cMOB9G__itemTitle {
  text-align: center;
  margin: 1rem 0 1.5rem;
}

.teaserNextBestActions-module__cMOB9G__text {
  margin-bottom: 1.75rem;
}

.teaserNextBestActions-module__cMOB9G__text a {
  color: var(--gold);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  text-decoration: none;
}

.teaserNextBestActions-module__cMOB9G__text a:hover {
  text-decoration: underline;
}

.teaserNextBestActions-module__cMOB9G__link {
  padding: 0 2rem;
  display: inline-flex !important;
}

@media (min-width: 600px) {
  .teaserNextBestActions-module__cMOB9G__container {
    padding: 0 1.25rem;
  }

  .teaserNextBestActions-module__cMOB9G__titleWrapper {
    padding-top: 0;
  }

  .teaserNextBestActions-module__cMOB9G__text {
    padding: 0 4rem;
  }
}

@media (min-width: 959px) {
  .teaserNextBestActions-module__cMOB9G__grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
  }
}

.TeaserBig-module__OD3lnW__teaserImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.TeaserBig-module__OD3lnW__teaserContent {
  background-color: var(--primary-1);
  text-align: left;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 40px;
}

.TeaserBig-module__OD3lnW__zbInjectedHtml p {
  margin: 0 0 var(--margin-md);
  word-wrap: break-word;
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-md);
  font-style: normal;
  font-stretch: normal;
  font-weight: var(--font-weight-light);
  letter-spacing: normal;
  line-height: 1.22;
}

.TeaserBig-module__OD3lnW__teaserCTA {
  background-color: var(--primary-3);
  color: var(--primary-5);
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-lg);
  letter-spacing: normal;
  text-align: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.25;
  text-decoration: none;
  display: flex;
}

.TeaserBig-module__OD3lnW__title {
  color: var(--primary-2);
  font-family: var(--zbx-font-kievit);
  font-size: var(--font-size-4xl);
  letter-spacing: normal;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  line-height: normal;
}

.TeaserBig-module__OD3lnW__overtitle {
  font-weight: 700;
  font-family: var(--zbx-font-kievit);
  font-size: var(--font-size-md);
  color: var(--primary-2);
  margin-bottom: 20px;
  padding: 0;
  line-height: 1.2;
}

.TeaserBig-module__OD3lnW__secondaryBtn {
  margin-top: 16px;
}

.TeaserBig-module__OD3lnW__gridOrderFirst {
  order: 1;
}

.TeaserBig-module__OD3lnW__gridOrderSecond {
  order: 2;
}

.TeaserBig-module__OD3lnW__ImageContainer {
  max-height: 540px;
}

@media (max-width: 600px) {
  .TeaserBig-module__OD3lnW__title {
    font-size: var(--font-size-3xl);
  }
}

@media (max-width: 768px) {
  .TeaserBig-module__OD3lnW__gridReverse {
    grid-auto-flow: dense;
    order: -1;
  }

  .TeaserBig-module__OD3lnW__gridReverse .TeaserBig-module__OD3lnW__gridOrderFirst {
    order: 2;
  }

  .TeaserBig-module__OD3lnW__gridReverse .TeaserBig-module__OD3lnW__gridOrderSecond {
    order: 1;
  }
}

.continuousText-module__JY8zfG__container {
  margin: auto;
}

.continuousText-module__JY8zfG__row {
  gap: var(--zbx-spacing-8);
  flex-direction: column;
  margin: auto;
  display: flex;
}

.continuousText-module__JY8zfG__column {
  padding: var(--zbx-spacing-3) var(--grid-gap) 0 var(--grid-gap);
  flex: 1;
}

.continuousText-module__JY8zfG__column.continuousText-module__JY8zfG__standalone {
  flex: 1;
  max-width: 100%;
}

.continuousText-module__JY8zfG__textContent {
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-light);
  margin: 0 0 var(--zbx-spacing-4);
  padding: 0;
}

.continuousText-module__JY8zfG__textContent h1, .continuousText-module__JY8zfG__textContent h2, .continuousText-module__JY8zfG__textContent h3, .continuousText-module__JY8zfG__textContent h4, .continuousText-module__JY8zfG__textContent h5, .continuousText-module__JY8zfG__textContent h6 {
  margin-bottom: .625rem;
}

.continuousText-module__JY8zfG__textContent p {
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-light);
  margin: 0 0 var(--zbx-spacing-8) 0;
}

.continuousText-module__JY8zfG__textContent a {
  color: var(--gold);
  font-weight: var(--font-weight-bold);
  text-decoration: none;
}

.continuousText-module__JY8zfG__textContent a:hover {
  text-decoration: underline;
}

.continuousText-module__JY8zfG__button {
  display: inline-flex;
}

@media (min-width: 960px) {
  [class*="tabs-module"]:has([class*="pageTitleElement-module"]) .continuousText-module__JY8zfG__column {
    padding: var(--zbx-spacing-3) 0;
  }

  .continuousText-module__JY8zfG__column {
    padding: var(--zbx-spacing-3) var(--zbx-spacing-5);
  }

  .continuousText-module__JY8zfG__row {
    flex-direction: row;
    padding: 0 5.4375rem;
  }
}

@media (max-width: 960px) {
  .continuousText-module__JY8zfG__row {
    margin-inline: var(--zbx-spacing-5);
  }
}

.howto-module__0rzAZW__zbHowTo {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.howto-module__0rzAZW__zbHowToMain {
  text-align: center;
  width: 100%;
  padding: var(--zbx-spacing-3) .3125rem;
  flex: 0 0 83%;
  margin: 0 auto;
}

.howto-module__0rzAZW__zbHowToMainTitle h2 {
  font-size: var(--font-size-3xl-plus);
  margin: 0 0 var(--margin-md);
  line-height: 1.2;
}

.howto-module__0rzAZW__zbHowToMainText {
  margin-bottom: var(--margin-xl);
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-2xl);
  font-style: normal;
  font-stretch: normal;
  font-weight: var(--font-weight-light);
  letter-spacing: normal;
  text-align: center;
  line-height: 1.17;
}

.howto-module__0rzAZW__zbHowToEntriesLeft, .howto-module__0rzAZW__zbHowToEntriesRight {
  flex: 0 0 83%;
  height: 100%;
  padding-top: 0;
}

.howto-module__0rzAZW__zbHowToEntriesLeft {
  order: 1;
}

.howto-module__0rzAZW__zbHowToEntriesRight {
  order: 2;
}

.howto-module__0rzAZW__zbHowToEntriesContainer {
  height: 100%;
  margin: 0 var(--grid-gap);
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.howto-module__0rzAZW__zbHowToEntry {
  margin: var(--margin-sm) 0;
}

.howto-module__0rzAZW__zbHowToEntry h5 {
  margin: 0 0 var(--margin-sm);
}

.howto-module__0rzAZW__zbHowToEntriesText {
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  font-style: normal;
  font-stretch: normal;
  font-weight: var(--font-weight-light);
  letter-spacing: normal;
  line-height: var(--line-height-sm);
  margin: 0 0 var(--margin-md);
}

.howto-module__0rzAZW__zbHowToEntriesText p {
  font-size: var(--font-size-md);
  margin: 0;
}

.howto-module__0rzAZW__zbYtCol {
  flex: 0 0 83%;
  order: 0;
  position: relative;
}

.howto-module__0rzAZW__zbYtVideoContainer {
  aspect-ratio: 9 / 16;
  max-width: 100%;
  height: 100%;
  position: relative;
}

.howto-module__0rzAZW__zbHideBordersOverlay {
  z-index: 1;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 -1px #fff, inset 0 1px #fff;
}

.howto-module__0rzAZW__zbHowToEntryTitle h5 {
  font-size: var(--font-size-xl);
}

.howto-module__0rzAZW__zbYtVideoContainer > div > div {
  height: -webkit-fill-available;
}

.howto-module__0rzAZW__zbYtVideoContainer iframe {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.howto-module__0rzAZW__zbYtVideoContainer > div > div > div {
  width: 40px;
  height: 40px;
}

@media (min-width: 600px) {
  .howto-module__0rzAZW__zbHowToMain, .howto-module__0rzAZW__zbYtCol {
    padding: var(--zbx-spacing-3) var(--zbx-spacing-5);
  }

  .howto-module__0rzAZW__zbHowToMainTitle h2 {
    font-size: var(--font-size-4xl-plus);
  }

  .howto-module__0rzAZW__zbHowToEntryTitle h5, .howto-module__0rzAZW__zbHowToMainText {
    font-size: var(--font-size-2xl);
  }
}

@media (min-width: 768px) {
  .howto-module__0rzAZW__zbHideBordersOverlay {
    box-shadow: inset 0 -15px #fff, inset 0 15px #fff;
  }
}

@media (min-width: 960px) {
  .howto-module__0rzAZW__zbHowToMain {
    width: 70%;
  }

  .howto-module__0rzAZW__zbYtCol {
    flex: 0 0 33%;
  }

  .howto-module__0rzAZW__zbHowToEntriesLeft, .howto-module__0rzAZW__zbHowToEntriesRight {
    flex: 0 0 25%;
    order: unset;
    align-self: center;
    padding: 0;
  }

  .howto-module__0rzAZW__zbHowToEntriesContainer {
    margin: 0 var(--grid-gap-medium-plus);
  }

  .howto-module__0rzAZW__zbHowToEntry {
    margin: var(--margin-10) 0;
  }
}

@media (min-width: 1024px) {
  .howto-module__0rzAZW__zbHideBordersOverlay {
    box-shadow: inset 0 -72px #fff, inset 0 72px #fff;
  }
}

@media (min-width: 1440px) {
  .howto-module__0rzAZW__zbHideBordersOverlay {
    box-shadow: inset -30px -17px #fff, inset 0 16px #fff;
  }
}

.infoboxProcess-module__-e_NVW__infoboxProcess {
  background-color: var(--primary-3);
  text-align: center;
  padding: var(--zbx-spacing-14) var(--zbx-spacing-10);
  flex-direction: column;
  align-items: center;
  display: flex;
}

.infoboxProcess-module__-e_NVW__bkg {
  background-color: var(--primary-1);
}

.infoboxProcess-module__-e_NVW__title {
  margin-top: 0;
  margin-bottom: var(--margin-12);
}

.infoboxProcess-module__-e_NVW__row {
  margin: calc(var(--zbx-spacing-3) * -1);
  flex-direction: column;
  display: flex;
}

.infoboxProcess-module__-e_NVW__steps {
  margin-bottom: var(--zbx-spacing-5);
}

.infoboxProcess-module__-e_NVW__stepsCol {
  padding: 0;
}

.infoboxProcess-module__-e_NVW__card {
  padding: var(--zbx-spacing-8) 0 var(--zbx-spacing-4) 0;
}

.infoboxProcess-module__-e_NVW__infoboxCard {
  flex-grow: 1;
}

.infoboxProcess-module__-e_NVW__infoboxCard ul:not(:has(li)) {
  display: none;
}

.infoboxProcess-module__-e_NVW__arrow {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  transform: translateX(-50%);
}

.infoboxProcess-module__-e_NVW__arrowCol {
  max-width: 3rem;
  position: relative;
  left: 50%;
}

.infoboxProcess-module__-e_NVW__arrowLine {
  background-color: var(--primary-2);
  width: 1px;
  height: 3rem;
}

.infoboxProcess-module__-e_NVW__arrowTip {
  transform: rotate(90deg);
}

.infoboxProcess-module__-e_NVW__btnContainer {
  margin-top: 2rem;
}

.infoboxProcess-module__-e_NVW__btnContainer a:not(.infoboxProcess-module__-e_NVW__ctaButton) {
  height: -moz-fit-content;
  height: fit-content;
}

.infoboxProcess-module__-e_NVW__btnContainer button {
  padding-inline: var(--zbx-spacing-4);
}

@media (min-width: 600px) {
  .infoboxProcess-module__-e_NVW__infoboxProcess {
    margin: var(--zbx-spacing-5);
  }
}

@media (min-width: 960px) {
  .infoboxProcess-module__-e_NVW__row {
    flex-direction: row;
    justify-content: center;
  }

  .infoboxProcess-module__-e_NVW__arrowCol {
    max-width: .00001rem;
    left: 0;
  }

  .infoboxProcess-module__-e_NVW__arrow {
    flex-direction: row;
    display: flex;
    position: absolute;
    top: 35px;
    left: auto;
    right: -6.25rem;
    transform: none;
  }

  .infoboxProcess-module__-e_NVW__arrowLine {
    width: 190px;
    height: 1px;
    transform: none;
  }

  .infoboxProcess-module__-e_NVW__arrowTip {
    transform: none;
  }
}

.teaserContact-module__OZaaFG__contact {
  background-image: url("/white-vertical-line-250px.png"), url("/zebra-texture.svg");
  background-repeat: repeat-x, no-repeat;
  background-size: auto, 100%;
  background-color: var(--primary-2);
  width: 100%;
  padding-bottom: 6rem;
}

.teaserContact-module__OZaaFG__contact__wrapper {
  background: var(--primary-3);
  max-width: 73.3%;
  box-shadow: var(--zbx-shadow-2);
  border-radius: 4px;
  margin: 0 auto;
  padding: 3.25rem;
}

.teaserContact-module__OZaaFG__contact__container {
  flex-direction: column;
  display: flex;
}

.teaserContact-module__OZaaFG__contact__title {
  text-align: center;
  font-size: 2.1875rem;
  font-family: var(--zbx-font-kievit);
  font-weight: var(--font-weight-bold);
  margin: var(--zbx-spacing-3);
  line-height: 1.2;
}

.teaserContact-module__OZaaFG__contact__description {
  text-align: center;
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-light);
  color: var(--secondary-1);
  margin-bottom: var(--margin-10);
  line-height: 1.17;
}

.teaserContact-module__OZaaFG__contact__description a {
  color: var(--gold);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  text-decoration: none;
}

.teaserContact-module__OZaaFG__contact__description a:hover {
  text-decoration: underline;
}

.teaserContact-module__OZaaFG__contact__description p {
  margin: var(--zbx-spacing-3) 0 var(--margin-lg-plus);
  font-size: var(--font-size-2xl);
  line-height: var(--line-height-sm);
  margin: .75rem 0 1.75rem;
}

.teaserContact-module__OZaaFG__contact__buttons {
  align-items: center;
  gap: var(--zbx-spacing-6);
  width: 100%;
  margin: calc(var(--zbx-spacing-3) * -1);
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  padding: .75rem;
  display: flex;
}

.teaserContact-module__OZaaFG__contact__buttons a {
  width: max-content;
  padding-left: 32px;
  padding-right: 32px;
  line-height: 1.33;
}

@media (min-width: 600px) {
  .teaserContact-module__OZaaFG__contact__title {
    font-size: var(--font-size-4xl-plus);
  }

  .teaserContact-module__OZaaFG__contact__description p {
    margin: var(--zbx-spacing-3) var(--zbx-spacing-3) var(--zbx-spacing-lg-plus);
  }
}

.pageTitleElement-module__NHoVcG__container {
  margin-inline: var(--zbx-spacing-5);
  flex-direction: column;
  display: flex;
}

.pageTitleElement-module__NHoVcG__container.pageTitleElement-module__NHoVcG__simple {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.pageTitleElement-module__NHoVcG__title {
  font-size: 3.125rem;
  font-family: var(--zbx-font-kievit);
  font-weight: var(--font-weight-bold);
  margin: 0;
  line-height: 1.1;
}

.pageTitleElement-module__NHoVcG__titleH2 {
  font-family: var(--zbx-font-kievit);
  font-size: 2.1875rem;
  font-weight: var(--font-weight-bold);
  margin: 0;
  line-height: 1.2;
}

.pageTitleElement-module__NHoVcG__text p {
  margin: 0;
  margin-bottom: var(--zbx-spacing-4);
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-2xl);
  font-style: normal;
  font-stretch: normal;
  font-weight: var(--font-weight-light);
  letter-spacing: normal;
  line-height: var(--line-height-sm);
}

.pageTitleElement-module__NHoVcG__text a, .pageTitleElement-module__NHoVcG__card > div > div > div > ul a {
  color: var(--gold);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  text-decoration: none;
}

.pageTitleElement-module__NHoVcG__text a:hover, .pageTitleElement-module__NHoVcG__card > div > div > div > ul a:hover {
  text-decoration: underline;
}

.pageTitleElement-module__NHoVcG__googleLink {
  font-family: var(--zbx-font-kievit);
  font-size: var(--font-size-38px);
  font-weight: var(--font-weight-light);
  text-align: center;
  margin: 0;
  margin-bottom: var(--zbx-spacing-5);
  flex-direction: column-reverse;
  width: 100%;
  height: auto;
  padding: 0;
  display: inline-flex;
}

.pageTitleElement-module__NHoVcG__googleLink img {
  width: -moz-fit-content;
  width: fit-content;
  max-width: 160px;
}

.pageTitleElement-module__NHoVcG__googleLink figure {
  padding: var(--zbx-spacing-6);
  margin: 0;
}

.pageTitleElement-module__NHoVcG__badge {
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: 959px) {
  .pageTitleElement-module__NHoVcG__badge {
    justify-content: left;
    align-items: flex-start;
    display: flex;
  }
}

.pageTitleElement-module__NHoVcG__card > div > div > * {
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-md);
  margin-bottom: 0;
}

.pageTitleElement-module__NHoVcG__card h4 {
  font-size: var(--font-size-xl);
  text-align: left;
  margin: 0;
}

.pageTitleElement-module__NHoVcG__card > div > div {
  display: inline-block;
}

.pageTitleElement-module__NHoVcG__card > div > div > div > ul {
  font-weight: var(--font-weight-light);
  padding-inline: var(--zbx-spacing-4);
  flex-direction: column;
  margin-bottom: .875rem;
  line-height: 1.56;
  display: flex;
}

.pageTitleElement-module__NHoVcG__link__wrapper {
  list-style: none;
}

.pageTitleElement-module__NHoVcG__link {
  font-size: var(--font-size-md);
  line-height: var(--line-height-3xl);
  gap: 1.25rem;
}

.pageTitleElement-module__NHoVcG__link span {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.pageTitleElement-module__NHoVcG__text__wrapper {
  padding: var(--zbx-spacing-3) var(--zbx-spacing-1-5px);
  flex-direction: column;
  gap: 1.25rem;
  display: flex;
}

.pageTitleElement-module__NHoVcG__card__wrapper {
  padding-block: var(--zbx-spacing-3);
  padding-inline: var(--zbx-spacing-1-5px);
}

.pageTitleElement-module__NHoVcG__card__wrapper h4 {
  font-size: var(--font-size-2xl);
}

.pageTitleElement-module__NHoVcG__googleImg__wrapper, .pageTitleElement-module__NHoVcG__image__wrapper {
  aspect-ratio: 4 / 3;
  width: 100%;
  position: relative;
}

.pageTitleElement-module__NHoVcG__googleImg__wrapper {
  margin-bottom: -6rem;
}

.pageTitleElement-module__NHoVcG__googleImg__wrapper img, .pageTitleElement-module__NHoVcG__image__wrapper img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.pageTitleElement-module__NHoVcG__pageTitleElementArrowLinks {
  gap: 0;
}

.pageTitleElement-module__NHoVcG__pageTitleElementArrowLinks h4 {
  height: auto;
  margin: auto;
}

.pageTitleElement-module__NHoVcG__pageTitleElementArrowLinks > div:first-child {
  padding-block: var(--zbx-spacing-4);
}

.pageTitleElement-module__NHoVcG__pageTitleElementArrowLinks a {
  gap: 1.5rem;
}

.pageTitleElement-module__NHoVcG__pageTitleElementArrowLinks svg {
  fill: var(--primary-2);
  width: 24px;
  height: 24px;
}

.pageTitleElement-module__NHoVcG__card [class*="cardText"] {
  align-items: flex-start;
  display: flex;
}

.pageTitleElement-module__NHoVcG__card div[class*="cardCTA"] {
  padding: 0;
}

@media (min-width: 600px) {
  .pageTitleElement-module__NHoVcG__title {
    font-size: var(--font-size-6xl);
    line-height: 1.09;
  }

  .pageTitleElement-module__NHoVcG__titleH2 {
    font-size: var(--font-size-4xl-plus);
    line-height: normal;
  }

  .pageTitleElement-module__NHoVcG__googleImg__wrapper {
    aspect-ratio: auto;
  }
}

@media (min-width: 960px) {
  .pageTitleElement-module__NHoVcG__container {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: var(--margin-12);
  }

  .pageTitleElement-module__NHoVcG__container:not(.pageTitleElement-module__NHoVcG__simple) {
    grid-template-columns: 1.5fr 1fr;
    margin-left: 0;
    margin-right: 0;
    padding: 0 5.4375rem;
    display: grid;
  }

  .pageTitleElement-module__NHoVcG__text__wrapper {
    min-width: 60%;
    max-width: 760px;
    margin: 0 auto;
  }

  .pageTitleElement-module__NHoVcG__googleLink {
    padding-inline: var(--zbx-spacing-8);
    width: auto;
  }

  .pageTitleElement-module__NHoVcG__googleLink figure {
    margin-bottom: 0;
  }

  .pageTitleElement-module__NHoVcG__card__wrapper {
    max-height: none;
    padding: 1.5rem 1.25rem;
  }

  .pageTitleElement-module__NHoVcG__card, .pageTitleElement-module__NHoVcG__pageTitleElementArrowLinks {
    margin-left: 5rem;
  }

  .pageTitleElement-module__NHoVcG__card > div > div > * {
    margin-bottom: 0;
  }
}

@media (min-width: 1440px) {
  .pageTitleElement-module__NHoVcG__card, .pageTitleElement-module__NHoVcG__pageTitleElementArrowLinks {
    margin-left: 6.5rem;
  }

  .pageTitleElement-module__NHoVcG__googleLink {
    margin-inline: var(--zbx-spacing-16);
  }
}

@media (min-width: 1441px) {
  .pageTitleElement-module__NHoVcG__card, .pageTitleElement-module__NHoVcG__pageTitleElementArrowLinks {
    margin-left: 7rem;
  }
}

.badge-module__4FC3VG__badge {
  background-color: #101010;
  background-image: var(--bg-image-zebra);
  border: 4px solid var(--primary-2);
  color: #fff;
  text-align: center;
  background-size: cover;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 204px;
  height: 204px;
  display: flex;
  position: relative;
}

.badge-module__4FC3VG__badge:before {
  content: "";
  border: 1px solid #fff;
  border-radius: 50%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.badge-module__4FC3VG__badge--rotate-right {
  transform: rotate(12deg);
}

.badge-module__4FC3VG__badge--rotate-left {
  transform: rotate(-12deg);
}

.badge-module__4FC3VG__badge__content {
  padding-bottom: 1.25rem;
}

.badge-module__4FC3VG__badge__heading {
  color: var(--primary-3);
  margin-bottom: .625rem;
  line-height: .97;
}

h4.badge-module__4FC3VG__badge__heading {
  font-size: var(--font-size-3xl);
}

.badge-module__4FC3VG__badge__subtitle {
  color: var(--primary-3);
  line-height: 1.11;
  font-weight: var(--font-weight-normal);
  margin: 0;
  padding: 0 .75rem .75rem;
}

@media (max-width: 600px) {
  .badge-module__4FC3VG__badge:before {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
  }

  .badge-module__4FC3VG__badge__heading {
    font-size: 1.25rem;
    line-height: 1.1;
  }

  .badge-module__4FC3VG__badge__subtitle {
    font-size: var(--font-size-sm);
    text-align: center;
    line-height: 1.17;
  }
}

.TeaserLocation-module__h3j0xG__teaserLocation {
  margin-bottom: 2rem;
}

.TeaserLocation-module__h3j0xG__title {
  text-align: center;
  margin-bottom: 2rem;
}

.TeaserLocation-module__h3j0xG__description {
  text-align: center;
  width: 60%;
  margin-bottom: 3.5rem;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .TeaserLocation-module__h3j0xG__description {
    width: 75%;
  }
}

.textCentered-module__aNjK8a__textCentered__container {
  padding-inline: var(--grid-gap);
  margin: 0 auto;
}

.textCentered-module__aNjK8a__textCentered__container img {
  max-width: 100%;
  height: auto;
}

.textCentered-module__aNjK8a__textCentered__container p {
  word-wrap: break-word;
  color: var(--primary-2);
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-lg);
  font-style: normal;
  font-stretch: normal;
  font-weight: var(--font-weight-light);
  letter-spacing: normal;
  line-height: var(--line-height-sm);
}

.textCentered-module__aNjK8a__centered__wrapper {
  box-sizing: border-box;
  width: 100%;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
}

.textCentered-module__aNjK8a__centered__background {
  background-color: var(--secondary-3);
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
  padding: 1.875rem 2.1875rem;
  position: relative;
}

.textCentered-module__aNjK8a__centered {
  width: 100%;
  font-family: var(--zbx-font-lato);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-light);
}

.textCentered-module__aNjK8a__centered a {
  color: var(--gold);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  text-decoration: none;
}

.textCentered-module__aNjK8a__centered a:hover {
  text-decoration: underline;
}

.textCentered-module__aNjK8a__centered table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
  display: table;
}

.textCentered-module__aNjK8a__centered tr {
  border-bottom: var(--faq-border);
}

.textCentered-module__aNjK8a__centered ol {
  margin-left: 1.5em;
  padding-left: 0;
  list-style-position: outside;
}

.textCentered-module__aNjK8a__centered ol > li::marker {
  font-weight: var(--font-weight-bold);
}

.textCentered-module__aNjK8a__centered ol > li h3, .textCentered-module__aNjK8a__centered ol > li p > strong {
  font-family: var(--zbx-font-kievit);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  padding-bottom: 1rem;
  line-height: normal;
}

.textCentered-module__aNjK8a__centered ol > li::marker {
  font-family: var(--zbx-font-kievit);
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  padding-bottom: 1rem;
  line-height: normal;
}

.textCentered-module__aNjK8a__centered ul {
  list-style: none;
}

.textCentered-module__aNjK8a__centered ul:not(:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--zbx-spacing-6);
}

.textCentered-module__aNjK8a__centered ul:not(:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--zbx-spacing-6);
}

.textCentered-module__aNjK8a__centered ul:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: var(--zbx-spacing-6);
}

.textCentered-module__aNjK8a__centered ul:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: var(--zbx-spacing-6);
}

.textCentered-module__aNjK8a__centered ul:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: var(--zbx-spacing-6);
}

.textCentered-module__aNjK8a__centered ul:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: var(--zbx-spacing-6);
}

.textCentered-module__aNjK8a__centered ul li {
  position: relative;
}

.textCentered-module__aNjK8a__centered ul li:before {
  content: "•";
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-lg);
  position: absolute;
  top: -.02rem;
  left: -1rem;
}

.textCentered-module__aNjK8a__centered h1, .textCentered-module__aNjK8a__centered h2, .textCentered-module__aNjK8a__centered h3, .textCentered-module__aNjK8a__centered h4, .textCentered-module__aNjK8a__centered h5, .textCentered-module__aNjK8a__centered h6 {
  margin: 0 0 var(--zbx-spacing-5) 0;
  padding: 0;
}

@media (max-width: 600px) {
  .textCentered-module__aNjK8a__textCentered__container {
    padding-inline: var(--zbx-spacing-3-plus);
  }
}

@media (min-width: 600px) {
  .textCentered-module__aNjK8a__textCentered__container {
    padding-inline: var(--grid-gap-medium-plus);
    margin: 0 auto;
  }
}

@media (min-width: 960px) {
  .textCentered-module__aNjK8a__centered__background {
    padding: 4.375rem 5rem;
  }
}

.text2Columns-module__C9OXkW__text2ColumnGrid [class*="grid"] {
  place-items: center;
}

.text2Columns-module__C9OXkW__wrapper {
  margin-inline: var(--zbx-spacing-5);
  padding: 0;
}

.text2Columns-module__C9OXkW__title {
  text-align: center;
  margin-bottom: 2rem;
}

.text2Columns-module__C9OXkW__heading {
  font-size: var(--font-size-4xl);
}

.text2Columns-module__C9OXkW__content {
  grid-template-columns: 1fr;
  font-family: sans-serif;
  display: grid;
}

.text2Columns-module__C9OXkW__content__left, .text2Columns-module__C9OXkW__content__right {
  padding: var(--zbx-spacing-3) var(--grid-gap) 0 var(--grid-gap);
}

:is(.text2Columns-module__C9OXkW__content__left, .text2Columns-module__C9OXkW__content__right) > :not(p) {
  margin: 0;
}

:is(.text2Columns-module__C9OXkW__content__left, .text2Columns-module__C9OXkW__content__right) p {
  margin-top: 0;
}

.text2Columns-module__C9OXkW__content__left a, .text2Columns-module__C9OXkW__content__right a {
  color: var(--gold);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  text-decoration: none;
}

.text2Columns-module__C9OXkW__content__left a:hover, .text2Columns-module__C9OXkW__content__right a:hover {
  text-decoration: underline;
}

@media (min-width: 600px) {
  .text2Columns-module__C9OXkW__text2ColumnGrid {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 960px) {
  .text2Columns-module__C9OXkW__content {
    grid-template-columns: 1fr 1fr;
  }

  .text2Columns-module__C9OXkW__wrapper {
    margin-left: 0;
    margin-right: 0;
    padding: 0 5.4375rem;
  }

  [class*="tabs-module"]:has([class*="pageTitleElement-module"]) .text2Columns-module__C9OXkW__content__left {
    padding: var(--zbx-spacing-3) var(--grid-gap) 0 0;
  }
}

